
import { dateFormatMixin } from '~/mixins'

export default {
  mixins: [
    dateFormatMixin,
  ],
  data () {
    return {
      // Layout data
      isMounted: false,
      isMenuOpen: false,
      activeTab: null,
      keyword: null,
      menuPrimaryItems: {
        Home: '/',
        'Programma\'s': {
          expand: true,
          children: {
            'BEAM Kerkdienst': '/programmas/eo-beam-kerkdienst',
            Denkstof: '/programmas/denkstof',
          },
        },
        'EO-Jongerendag': 'https://eojongerendag.nl/',
        Magazine: '/magazine',
        'BEAM-wear': this.$config.baseUrlMerchandise,
        Contact: '/contact',
      },
      menuSecondaryItems: {
        'BEAM Team': '/over-beam/team',
        'Over BEAM': '/over-beam',
        'Word lid': this.$config.baseUrlMembership,
        Stage: '/over-beam/stage',
        'Adverteren bij BEAM Magazine': '/adverteren',
      },
      menuSocialItems: {
        tiktok: 'https://www.tiktok.com/@eobeam',
        instagram: 'https://www.instagram.com/eobeam/',
        youtube: 'https://www.youtube.com/eobeam',
        whatsapp: 'https://wa.me/31615878111',
        spotify: 'https://open.spotify.com/user/eobeam/',
        facebook: 'https://www.facebook.com/jongeren/',
      },
      // Event data
      isEventInFuture: null,
      isEventStartingToday: null,
      isEventIn30Min: null,
      isEventHappening: null,
      isEventInPast: null,
      readableDate: null,
      articles: [],
      callToAction: null,
      changedOn: null,
      description: [],
      endDate: null,
      environmentId: null,
      faq: [],
      hero: null,
      heroVideo: null,
      heroVideoStill: null,
      id: null,
      lineUp: [],
      livestream: null,
      location: null,
      program: [],
      publishOn: null,
      seo: null,
      slug: null,
      social: null,
      startDate: null,
      subtitle: null,
      terms: [],
      tickets: [],
      title: null,
      topic: null,
    }
  },
  async fetch () {
    // Get event for this slug
    const [
      { data: { items: events = [] } = {} },
    ] = await Promise.all([
      this.$api.events.getOneBySlug(this.$route.params.slug, {
        domain: this.$config.preprEnvironment,
      }),
    ])

    // Check publication if exists
    if (!events || !events.length) {
      return false
    }

    // Save event data
    this.callToAction = events[0].callToAction
    this.changedOn = events[0].changedOn
    this.description = events[0].description
    this.endDate = events[0].endDate
    this.environmentId = events[0].environmentId
    this.faq = events[0].faq
    this.hero = events[0].hero
    this.heroVideo = events[0].heroVideo
    this.heroVideoStill = events[0].heroVideoStill
    this.id = events[0].id
    this.lineUp = events[0].lineUp
    this.livestream = events[0].livestream
    this.location = events[0].location
    this.program = events[0].program
    this.publishOn = events[0].publishOn
    this.seo = events[0].seo
    this.slug = events[0].slug
    this.social = events[0].social
    this.startDate = events[0].startDate
    this.subtitle = events[0].subtitle
    this.terms = events[0].terms
    this.tickets = events[0].tickets
    this.title = events[0].title
    this.topic = events[0].topic

    // Get articles linked to this event
    const {
      data: {
        items: articles = [],
      } = {},
    } = await this.$api.articles.getAll({
      limit: 3,
      event: this.id,
    })
    this.articles = articles

    // Set breadcrumbs for the page
    this.$store.dispatch('breadcrumbs/set', {
      path: `/evenementen/${this.slug}`,
      eoRoot: true,
      floating: true,
      width: 10,
      items: [
        {
          to: '/',
          label: 'BEAM',
        },
        {
          label: this.title,
        },
      ],
    })
  },
  computed: {
    currentPath () {
      return this.$route.path
    },
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    pathWithoutTrailingSlash () {
      return this.$route.path.replace(/\/$/, '')
    },
  },
  watch: {
    startDate: {
      handler: function () {
        // Create a readable date every second, if needed
        this.setReadableDate()
        if (this.isEventIn30Min || this.isEventHappening) {
          setInterval(this.setReadableDate.bind(this), 1000)
        }
      },
      immediate: true,
    },
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    onNavigate () {
      this.isMenuOpen = false
    },
    onSearch (keyword) {
      this.$router.push({
        path: '/zoeken',
        query: {
          invoer: keyword,
        },
      })
      this.isMenuOpen = false
      this.keyword = null
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
    setReadableDate () {
      if (!this.startDate) return
      this.$moment.relativeTimeThreshold('ss', 0)

      // Get relative dates
      const now = this.$moment()
      const start = isNaN(this.startDate) ? this.$moment(this.startDate) : this.$moment.unix(this.startDate)
      const minutesToStart = start.diff(now, 'minutes', true)
      const minutesFromStart = now.diff(start, 'minutes', true)
      let end
      let minutesToEnd
      let minutesFromEnd
      if (this.endDate) {
        end = isNaN(this.endDate) ? this.$moment(this.endDate) : this.$moment.unix(this.endDate)
        minutesToEnd = end.diff(now, 'minutes', true)
        minutesFromEnd = now.diff(end, 'minutes', true)
      }

      // Set relative date booleans
      this.isEventStartingToday = start.isSame(now, 'day') && minutesToStart > 0
      this.isEventIn30Min = minutesToStart > 0 && minutesToStart < 30
      this.isEventInFuture = minutesToStart > 0
      if (this.endDate) {
        this.isEventHappening = minutesToStart < 0 && minutesToEnd > 0
        this.isEventInPast = minutesFromEnd > 0
      } else {
        this.isEventHappening = minutesToStart == 0
        this.isEventInPast = minutesFromStart > 0
      }
      
      // Generate human readable date
      this.readableDate =
        this.isEventIn30Min ? `Begint ${start.fromNow()}` :
        this.isEventHappening && minutesToStart > -5 ? 'Zojuist begonnen' :
        this.isEventHappening && this.endDate ? `Eindigt ${this.dateFormat.past(this.endDate)}` :
        this.isEventStartingToday ? `Vandaag om ${this.dateFormat.time(this.startDate)}` :
        this.isEventInFuture ? `${this.dateFormat.dayDateAndTime(this.startDate)}u` :
        this.isEventInPast && minutesToStart > -5 ? 'Zojuist plaatsgevonden' :
        this.isEventInPast && minutesFromEnd < 5 ? 'Zojuist geëindigd' :
        `Dit evenement is voorbij`
    },
  },
}
